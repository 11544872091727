.insurance_page {

    .section_1 {
        height: 830px;
        max-height: 830px;
        position: relative;
        background-color: #7777;  /* El color de fondo */
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        position: relative;
        /*background: linear-gradient(rgba(47, 23, 15, 0.65), rgba(47, 23, 15, 0.65));*/
        /*background-attachment: fixed;*/
        background-position: center;
        background-size: cover;
        // z-index:90;

        .bg_img {
            object-fit: cover;
        }
    
        .first_img{
            object-fit: cover;
            aspect-ratio: 16/9;
        }
    
        .content {
            z-index: 1;
            display: flex;
            align-items: center;
            height: 90%;
            width: 100%;
        }
    }
    

    .section_3 {
        background-color: var(--color-gray-light);
    }
}

@media screen and (max-width: 768px) {
    .insurance_page{
        .section_1{
            height: 1100px!important;
            max-height: 1100px!important;
        }

        .img-car{
            display: none;
        }
    }


}

.text-ofrecemos{
    background-color: var(--color-orange);
    padding: 10px;
    margin-top: 120px;
    margin-bottom: 20px;
    margin-left: 100px;
    position: relative;
}



@media screen and (max-width: 768px) {
    .content{
        display: block!important;
    }

    .text-ofrecemos{
        background-color: var(--color-orange);
        padding: 10px;
        margin-top: 70px;
        margin-left: 0px;
        margin-bottom: 20px;
        position: relative;
    }

}



.workshop_and_service_drop_lists,
.workshop_and_service_questions {
    background-color: #f6f7f9;

    .drop_lists {
        padding: 0 8%;

        h3 {
            font-size: 32px;
            font-weight: bold;
        }

        span {
            color: #ec671b;
        }

        .title_drop_list {
            font-size: 32px;
            font-weight: bold;
        }

        .subtitle_drop_list {
            font-size: 18px;
            font-weight: bold;
        }

        .item_drop_list {
            font-size: 18px;
            color: #000;
        }
    }
}

#amoforms_form{
    display: none!important;
}

