.insurance_page {

    .section_1-insurance {
        max-height:400px;
        height: 400px;
        position: relative;
        background-image: url("../../../../../../public/assets/services/seguro-1.png");
        background-size: cover;
        .bg_img {
            object-fit: cover;
        }
    
        .first_img{
            object-fit: cover;
            aspect-ratio: 16/9;
        }
    
        .content {
            z-index: 1;
            display: flex;
            align-items: center;
            height: 90%;
            width: 100%;
        }
    }
    

    .section_3 {
        background-color: var(--color-gray-light);
    }
}

.text-ofrecemos{
    background-color: var(--color-orange);
    padding: 10px;
    margin-top: 120px;
    margin-bottom: 20px;
    margin-left: 100px;
    position: relative;
}



@media screen and (max-width: 768px) {
    .content{
        display: block!important;
    }
    .text-ofrecemos{
        background-color: var(--color-orange);
        padding: 10px;
        margin-top: 70px;
        margin-left: 0px;
        margin-bottom: 20px;
        position: relative;
    }

}



.workshop_and_service_drop_lists,
.workshop_and_service_questions {
    background-color: #f6f7f9;

    .drop_lists {
        padding: 0 8%;

        h3 {
            font-size: 32px;
            font-weight: bold;
        }

        span {
            color: #ec671b;
        }

        .title_drop_list {
            font-size: 32px;
            font-weight: bold;
        }

        .subtitle_drop_list {
            font-size: 18px;
            font-weight: bold;
        }

        .item_drop_list {
            font-size: 18px;
            color: #000;
        }
    }
}

