.sign_in_page {
    .content_page {
        input.form-control {
            background-color: var(--color-gray-light);
        }

    }

    .footer {
        background-color: var(--color-gray-light);
    }
}

.item-detail-user{
    gap: 30px;
    width: 90%;
    max-width: 652px;
    height: 140px;
    padding-top: 20x;
    padding-left: 30px;
    padding-right: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    background-color: white;
    align-items: center;
    margin-top:10px
}

.item-detail-user-image{
    gap: 30px;
    width: 90%;
    max-width: 652px;
    background-color: white;
    // padding-left: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top:10px
}


.wcar_img_2Contact {
    background-image: url(../../../../../../public/assets/home/car_02.webp);
    background-repeat: no-repeat;
    background-size: cover;
    top: -7.5%;
    left: -20%;
    right: 0;
    bottom: 25%;
}