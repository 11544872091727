
  .container-linktree{
    max-height: 100vh;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    position: absolute;
    position: relative;
  }

  .container-background{
    position: absolute;
    top:0;
    left:0;
    right: 0;
    bottom:0;
    background-color: #f5f7f9;
    z-index: -1;

  }

  .text{
    position: absolute;
    font-size:40vh;
    color:#fff;
    left:-60px;
    font-weight: 700;
  }
  

  .line-1{
    background-color: #ec671b;
    width: 450px;
    height: 20px;
    transform: rotate(45deg);
    position: absolute;
    right: -130px;
  }

  .line-2{
    background-color: #00fefe;
    width: 550px;
    height: 20px;
    transform: rotate(45deg);
    position: absolute;
    right: -120px;
  }

  .line-3{
    background-color: #ec671bcc;
    width: 400px;
    height: 140px;
    transform: rotate(-30deg);
    position: absolute;
    right: -90px;
    bottom:-35px;
  }

  .line-4{
    background-color: #000;
    width: 280px;
    height: 70px;
    transform: rotate(-30deg);
    position: absolute;
    right: -140px;
    bottom:0;
  }
  
  